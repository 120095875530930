import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
	FormControl,
	Validators,
	FormGroup,
	FormGroupDirective
} from "@angular/forms";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from "./../../src/environments/environment";
import { config } from "../../src/configurations/config";
import { MatSnackBar } from "@angular/material";

@Component({
	selector: "app-contact",
	templateUrl: "./contact.component.html",
	styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
	name = new FormControl("", [Validators.required]);
	email = new FormControl("", [Validators.required, Validators.email]);
	msg = new FormControl("", [Validators.required]);

	@ViewChild(FormGroupDirective) form: FormGroupDirective;

	contactForm = new FormGroup({
		name: this.name,
		email: this.email,
		msg: this.msg
	});

	constructor(
		private http: HttpClient,
		private captcha: ReCaptchaV3Service,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {}

	onSubmit() {
		this.captcha.execute(config.reCaptchaSiteKey, "homepage", token => {
			const data = this.contactForm.value;
			data.recaptcha = token;

			this.http
				.post(`${environment.phpServer}/contact.php`, data)
				.subscribe({
					complete: () => {
						this.snackBar.open(
							"Thanks for your message, I will check it out ASAP!",
							"OK",
							{
								duration: 3000,
								panelClass: "snackbar-positive"
							}
						);

						this.form.resetForm();
					},
					error: (err: HttpErrorResponse) => {
						const msg =
							err.error.message ||
							"Something went wrong, try again later.";

						this.snackBar.open(msg, "OK", {
							panelClass: "snackbar-negative"
						});
					}
				});
		});
	}

	getRequiredErrorMsg(formControl: FormControl) {
		return formControl.hasError("required") ? "You must enter a value" : "";
	}

	getNameErrorMsg() {
		return this.getRequiredErrorMsg(this.name);
	}

	getEmailErrorMsg() {
		return this.email.hasError("email")
			? "Not a valid email"
			: this.getRequiredErrorMsg(this.email);
	}

	getMsgErrorMsg() {
		return this.getRequiredErrorMsg(this.msg);
	}
}
