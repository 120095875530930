import { environment } from "./../environments/environment";
import { Component, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import smoothscroll from "smoothscroll-polyfill";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements AfterViewInit {
	environment = environment;

	@ViewChild("copyrightYear")
	copyrightYear: ElementRef;

	constructor(
		private icon: MatIconRegistry,
		private sanitizer: DomSanitizer
	) {
		this.icon.addSvgIcon(
			"up-arrow",
			this.sanitizer.bypassSecurityTrustResourceUrl(
				"../assets/icons/up-arrow.svg"
			)
		);

		smoothscroll.polyfill();
	}

	ngAfterViewInit(): void {
		this.copyrightYear.nativeElement.innerHTML += new Date().getFullYear();
	}

	toTop(event: Event) {
		const options: ScrollIntoViewOptions = {
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		};

		document.querySelector("app-root").scrollIntoView(options);
	}
}
