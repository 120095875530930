import { SortArrayPipe } from "./../pipes/sort-array.pipe";
import { WorkService } from "./work.service";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WorkEntryComponent } from "./work-entry/work-entry.component";
import { WorkComponent } from "./work.component";

import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material";
import { JsonDeMultiLinerPipe } from "../pipes/jsonDeMultiLiner.pipe";
import { WorkModalComponent } from "./work-modal/work-modal.component";
import { MatTabsModule } from "@angular/material/tabs";
import { SafeContentPipe } from "../pipes/safe-content.pipe";

@NgModule({
	declarations: [
		WorkEntryComponent,
		WorkComponent,
		SortArrayPipe,
		JsonDeMultiLinerPipe,
		WorkModalComponent,
		SafeContentPipe
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatRippleModule,
		MatTabsModule
	],
	exports: [WorkComponent],
	bootstrap: [WorkComponent],
	providers: [WorkService]
})
export class WorkModule {}
