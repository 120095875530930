import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	AfterViewInit
} from "@angular/core";
import * as t from "trianglify";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@ViewChild("header", { read: ElementRef })
	header: ElementRef;

	constructor() {}

	ngOnInit() {}

	ngAfterViewInit(): void {
		const pattern = t({
			width: 1920,
			height: 1080,
			cell_size: 100,
			variance: 0.75,
			x_colors: ["7ED4FF", "5EB6E1", "3F98C4", "1F7AA7", "005C8A"]
		});

		const svg = pattern.svg({ includeNamespace: true }).outerHTML;
		const svgUrl = `url("data:image/svg+xml;charset=UTF-8,${encodeURI(
			svg
		)}")`;

		this.header.nativeElement.style.backgroundImage = svgUrl;
	}

	scrollToWork(event: Event) {
		const options: ScrollIntoViewOptions = {
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		};

		document.querySelector("#work").scrollIntoView(options);

		event.preventDefault();
	}
}
