import { Component, OnDestroy, AfterViewInit } from "@angular/core";

import mixitup from "mixitup";
import { WorkService } from "./work.service";
import { MatTabChangeEvent } from "@angular/material";

@Component({
	selector: "app-work",
	templateUrl: "./work.component.html",
	styleUrls: ["./work.component.scss"]
})
export class WorkComponent implements OnDestroy, AfterViewInit {
	mixer: mixitup;

	constructor(public workService: WorkService) {}

	ngAfterViewInit() {
		this.workService.workEntries.subscribe(() => {
			if (this.workService.isWorkEntriesLoaded && this.mixer == null) {
				this.mixer = mixitup(document.getElementById("work-mixitup"), {
					load: {
						filter: ".featured"
					},
					animation: {
						queueLimit: 5
					}
				});
			}
		});
	}

	ngOnDestroy() {
		if (this.mixer != null) {
			this.mixer.destroy();
		}
	}

	changeCategory(event: MatTabChangeEvent) {
		this.mixer.filter(
			this.workService.workData.categories[event.index].filter
		);
	}
}
