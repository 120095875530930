import { Component, OnInit } from "@angular/core";
import { HeaderComponent } from "../header/header.component";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
	skills = require("../assets/skills.json");

	constructor() {}

	ngOnInit() {}

	goToContact() {
		const options: ScrollIntoViewOptions = {
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		};

		document.querySelector("#contact").scrollIntoView(options);

		event.preventDefault();
	}
}
