import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	ViewChildren,
	QueryList,
	AfterViewInit,
	HostListener
} from "@angular/core";

@Component({
	selector: "app-timeline",
	templateUrl: "./timeline.component.html",
	styleUrls: ["./timeline.component.scss"]
})
export class TimelineComponent implements OnInit, AfterViewInit {
	entries: TimelineEntry[] = require("../../../assets/timeline.json").entries;

	@ViewChild("ul")
	ul: ElementRef;

	@ViewChildren("to, from")
	spans: QueryList<ElementRef>;

	constructor() {}

	ngOnInit() {}

	ngAfterViewInit(): void {
		this.resize();
	}

	@HostListener("window:resize")
	private resize() {
		let widest = 0;
		this.spans.forEach(element => {
			const width = Math.abs(element.nativeElement.offsetLeft);
			if (width > widest) {
				widest = width;
			}
		});
		this.ul.nativeElement.style.paddingInlineStart = `${widest}px`;
	}
}

interface TimelineEntry {
	title: String;
	content: String;
	type: String;
	from: String;
	to: String;
}
