export interface WorkData {
	workUrls: string[];
	categories: Category[];
}

export interface Category {
	hide?: boolean;
	display: string;
	filter: string;
}

export class WorkEntry {
	constructor(data: WorkEntry) {
		Object.assign(this, data);
	}

	uri: string;
	name: string;
	iconPath: string;
	category: string;
	description: string[];
	purview: string[];
	platforms: Link[];
	technologies: Link[];
	download: Download[];
	images: any[];
	videos: string[];
	extra: string;

	getPath(): string {
		return `../assets/work/${this.uri}`;
	}

	getFilePath(file: string): string {
		return `${this.getPath()}/${file}`;
	}

	getIconPath(): string {
		if (!this.iconPath) {
			return "../assets/work/placeholder-icon.svg";
		}

		return this.getFilePath(this.iconPath);
	}
}

export interface Link {
	name: string;
	source?: string;
}

export interface Download {
	name: string;
	source: string;
	class: string;
}
