import { Component, AfterViewInit } from '@angular/core';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements AfterViewInit {

	constructor() { }

	ngAfterViewInit() {
		setTimeout(() => {
			document.getElementById('underconstruction').style.filter = 'blur(0px)';
			document.getElementById('underconstruction').style.opacity = '1';
		});
	}

}
