import { MatButtonModule } from "@angular/material/button";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HomeModule } from "../home/home.module";
import { AppComponent } from "./app.component";
import { MaintenanceComponent } from "../maintenance/maintenance.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material";

@NgModule({
	declarations: [AppComponent, MaintenanceComponent],
	imports: [
		BrowserModule,
		HomeModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatIconModule,
		MatButtonModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
