import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortArray'
})
export class SortArrayPipe implements PipeTransform {
	transform(array: Array<any>, field: string): Array<any> {
		if (array == null) {
			return null;
		}

		array.sort((a: any, b: any) => {
			const aVal = a[field];
			const bVal = b[field];

			if (aVal === undefined) {
				return 1;
			}

			if (bVal === undefined) {
				return -1;
			}

			if (aVal < bVal) {
				return -1;
			} else if (aVal > bVal) {
				return 1;
			} else {
				return 0;
			}
		});
		return array;
	}
}
