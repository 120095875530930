import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: "safeContent"
})
export class SafeContentPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(content: string, type: string) {
		switch (type.toLowerCase()) {
			case "resource":
				return this.sanitizer.bypassSecurityTrustResourceUrl(content);
			case "html":
				return this.sanitizer.bypassSecurityTrustHtml(content);
			case "style":
				return this.sanitizer.bypassSecurityTrustStyle(content);
			case "script":
				return this.sanitizer.bypassSecurityTrustScript(content);
			case "url":
				return this.sanitizer.bypassSecurityTrustUrl(content);
			default:
				return content;
		}
	}
}
