import { Component, OnInit, Input, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { WorkEntry } from "../work-data-types";

@Component({
	// tslint:disable-next-line:component-selector
	selector: ".app-work-entry",
	templateUrl: "./work-entry.component.html",
	styleUrls: ["./work-entry.component.scss"]
})
export class WorkEntryComponent implements OnInit {
	constructor(private router: Router) {}

	@Input() currentWorkEntry: WorkEntry;
	@Input() isGap;
	@HostBinding("class.gap") isGapStyled = this.isGap;

	ngOnInit() {
		this.isGapStyled = this.isGap;
	}

	clicked() {
		this.router.navigate([
			{ outlets: { portfolio: this.currentWorkEntry.uri } }
		]);
	}
}
