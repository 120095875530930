import {
	Component,
	OnInit,
	HostListener,
	OnDestroy,
	AfterViewInit,
	AfterViewChecked
} from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";
import { WorkService } from "../work.service";

import flickity from "flickity";
import { WorkEntry } from "../work-data-types";

@Component({
	selector: "app-work-modal",
	templateUrl: "./work-modal.component.html",
	styleUrls: ["./work-modal.component.scss"]
})
export class WorkModalComponent implements OnInit, OnDestroy, AfterViewChecked {
	isDataLoaded: boolean;
	currentWorkEntry: WorkEntry;
	flick: flickity;
	lastVideoElem: any;

	@HostListener("document:keydown", ["$event"]) onKeydownHandler(
		event: KeyboardEvent
	) {
		if (event.key === "Escape") {
			this.close();
		}
	}

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private workService: WorkService
	) {}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(params => {
			this.workService.getWorkEntryByUri(params["uri"]).then(data => {
				this.currentWorkEntry = data;
				this.isDataLoaded = true;
			});
		});

		this.open();
	}

	ngAfterViewChecked(): void {
		this.createFlick();
	}

	createFlick(): void {
		if (this.flick != null || !this.isDataLoaded) {
			return;
		}

		this.flick = new flickity("#work-entry-page-carousel", {
			wrapAround: true,
			cellAlign: "center",
			pageDots: false,
			lazyLoad: 1,
			adaptiveHeight: true
		});

		this.flick.on("change", (index: number) => this.slideChange(index));
		this.slideChange(0);
	}

	slideChange(index: number): any {
		if (this.flick.cells.length <= 0) {
			return;
		}

		if (this.lastVideoElem) {
			this.lastVideoElem.contentWindow.postMessage(
				// tslint:disable-next-line: quotemark
				'{"event":"command","func":"' + "pauseVideo" + '","args":""}',
				"*"
			);
		}

		const elem: Element = this.flick.cells[index].element.firstElementChild;

		if (elem && elem.tagName === "IFRAME") {
			this.lastVideoElem = elem;
		}
	}

	ngOnDestroy() {
		if (this.flick != null) {
			this.flick.destroy();
		}

		this.close();
	}

	open() {
		document.body.style.overflow = "hidden";
	}

	close() {
		this.router.navigate([{ outlets: { portfolio: null } }]);
		document.body.style.overflow = "auto";
	}
}
