import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'jsonDeMultiLiner'
})
export class JsonDeMultiLinerPipe implements PipeTransform {

	transform(value: string[], escapeCharacter: any = '\n'): any {
		if (value == null) {
			return null;
		}

		return value.join(escapeCharacter);
	}

}
