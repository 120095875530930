import { MarkdownToHtmlModule } from "markdown-to-html-pipe";
import { MatIconModule } from "@angular/material/icon";
import { HttpClientModule } from "@angular/common/http";
import { WorkModule } from "./../work/work.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HomeComponent } from "./home.component";
import { HeaderComponent } from "../header/header.component";
import { FillScreenComponent } from "../app/components/fill-screen/fill-screen.component";
import { ContactComponent } from "../contact/contact.component";
import {
	MatFormFieldModule,
	MatInputModule,
	MatButtonModule,
	MatSnackBarModule
} from "@angular/material";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { TimelineComponent } from "../app/components/timeline/timeline.component";

@NgModule({
	declarations: [
		HomeComponent,
		HeaderComponent,
		FillScreenComponent,
		ContactComponent,
		TimelineComponent
	],
	imports: [
		CommonModule,
		WorkModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxCaptchaModule,
		MatSnackBarModule,
		MatIconModule,
		MarkdownToHtmlModule
	],
	exports: [HomeComponent],
	bootstrap: [HomeComponent]
})
export class HomeModule {}
