import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { WorkEntry, WorkData } from "./work-data-types";

@Injectable()
export class WorkService {
	isWorkEntriesLoaded: boolean;

	workData: WorkData = require("../assets/work/work-data.json");
	workUrls: string[] = this.workData.workUrls;
	workEntries: Observable<WorkEntry[]>;

	constructor(private http: HttpClient) {
		this.workEntries = this.getWorkEntries();
		this.workEntries.subscribe(() => (this.isWorkEntriesLoaded = true));
	}

	getWorkEntries() {
		const urlObservables: Observable<WorkEntry>[] = new Array(
			this.workUrls.length
		);

		for (let i = 0; i < this.workUrls.length; i++) {
			const element = this.workUrls[i];

			urlObservables[i] = this.http.get<WorkEntry>(element).pipe(
				map((res: WorkEntry) => {
					return new WorkEntry(res);
				})
			);
		}

		return forkJoin(urlObservables);
	}

	getWorkEntryByUri(uri): Promise<WorkEntry> {
		return this.workEntries.toPromise().then(prom => {
			return prom.find(workEntry => workEntry.uri === uri);
		});
	}
}
